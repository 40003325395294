import classNames from 'classnames';

import TopArrow from '../../../assets/svgs/topArrow';

import style from './productContributionGridHeader.module.scss';

const ProductContributionGridHeader = ({
  lensDetails = [],
  name,
  image,
  notes,
  productPage = false,
  onHeaderClick,
  index = 0,
  accordionContentShow,
  icon
}: {
  lensDetails?: any[];
  name?: string;
  image?: string;
  notes?: string;
  productPage?: boolean;
  onHeaderClick?: any;
  index?: number | undefined;
  accordionContentShow?: any;
  icon?: any;
}): JSX.Element => (
  <div>
    <div
      className={classNames(style.productContributionGridHeader, {
        [style.productContributionHeaderBorder]: !lensDetails.length
      })}>
      <div
        className={classNames({
          [style.productPageContributionHeader]: productPage,
          [style.productContributionGridTitle]: !productPage
        })}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          {image && (
            <div className={style.productContributionIcon}>
              <img src={image} alt="accordion header" />
            </div>
          )}
          {icon && icon}
          {name && <div>{name}</div>}
        </div>
        {productPage && (
          <button
            onClick={() => onHeaderClick(index)}
            aria-label={`Toggle accordion ${name}`}
            style={{ cursor: 'pointer', border: 'none', background: 'inherit' }}>
            <TopArrow
              className={classNames(
                accordionContentShow[index]?.value ? style.rotateZero : style.rotate360,
                'pdfAccordionIconInvisible'
              )}
            />
          </button>
        )}
      </div>
      {notes && <div className={style.productContributionGridMessage}>{notes}</div>}
    </div>
  </div>
);

export default ProductContributionGridHeader;
