import { Barrels } from '../../assets/svgs/barrels';
import { Car } from '../../assets/svgs/car';
import { Cloud } from '../../assets/svgs/cloud';
import { Football } from '../../assets/svgs/football';
import { Gallons } from '../../assets/svgs/gallons';
import { HouseField } from '../../assets/svgs/houseField';
import { LandFill } from '../../assets/svgs/landfill';
import { Tree } from '../../assets/svgs/tress';

export interface IImpact {
  qunatity?: number;
  start: string;
  middle: string;
  end: string;
  bolder: string;
  icon?: any;
  hasBackground?: boolean;
}

type FunFactsType = {
  barrels_of_oil?: number;
  cars_off_road?: number;
  co2_pounds?: number;
  cubic_yards?: number;
  football_fields?: number;
  gallons_of_gas?: number;
  homes_powered?: number;
  trees_saved?: number;
  icon?: any;
  contributing_units?: number;
};

export const Impact: { [key: string]: IImpact } = {
  barrels_of_oil: {
    start: 'Barrels of oil ',
    icon: <Barrels />,
    middle: '',
    end: 'saved by utilizing recycled content',
    bolder: 'start',
    hasBackground: true
  },
  gallons_of_gas: {
    start: 'Gallons of gasoline ',
    middle: '',
    icon: <Gallons />,
    end: 'saved equivalent from home energy savings.',
    bolder: 'start',
    hasBackground: true
  },
  trees_saved: {
    start: 'Trees saved ',
    middle: '',
    icon: <Tree />,
    end: 'through Particle Board Work Surfaces and avoiding cardboard packaging.',
    bolder: 'start',
    hasBackground: true
  },
  cars_off_road: {
    start: 'Saved energy equivalent of taking many ',
    middle: 'cars off the road ',
    icon: <Car />,
    end: 'per year',
    bolder: 'middle',
    hasBackground: true
  },
  homes_powered: {
    start: 'Saved energy equivalent to ',
    middle: ' powering this many homes ',
    icon: <HouseField />,
    end: 'per year.',
    bolder: 'middle',
    hasBackground: true
  },
  football_fields: {
    start: 'Saved landfill space equivalent to this many American ',
    middle: ' football fields ',
    icon: <Football />,
    end: 'a foot deep with waste.',
    bolder: 'middle',
    hasBackground: true
  },
  cubic_yards: {
    start: 'Cubic yards of ',
    middle: ' football fields ',
    icon: <LandFill />,
    end: 'landfill space avoided.',
    bolder: 'end',
    hasBackground: false
  },
  co2_pounds: {
    start: 'Carbon dioxide emissions ',
    middle: '',
    icon: <Cloud />,
    end: 'saved (pounds).',
    bolder: 'start',
    hasBackground: false
  }
};

export const funFactObject = (funFacts: FunFactsType): { [key: string]: any } => {
  let factsObject: any = [];
  const { contributing_units: contributingUnits, ...rest } = funFacts;

  for (const key in rest) {
    factsObject = [
      ...factsObject,
      {
        ...Impact[key],
        qunatity:
          key === 'homes_powered'
            ? roundWithDecimals(funFacts[key as keyof FunFactsType])
            : roundWithoutDecimals(funFacts[key as keyof FunFactsType])
      }
    ];
  }

  return {
    factsObject,
    contributingUnits
  };
};

export const roundWithoutDecimals = (value: number): number =>
  parseInt(parseFloat(value.toFixed(0)) as unknown as string);

export const roundWithDecimals = (value: number): number => parseFloat(value.toFixed(2));
