import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import BackIcon from '../../assets/svgs/backArrow';
import RCButton from '../shared/Button/Button';

import Padding from './Padding';

import style from './projectEvaluation.module.scss';
const Navbar = ({
  backToProject,
  projectName
}: {
  backToProject?: () => object;
  projectName?: any;
}): JSX.Element => {
  const { t } = useTranslation();
  const [isButtonHovered, setIsButonHoverd] = useState(false);

  return (
    <Padding>
      <>
        <div className={`${style.notVisibleLogo} displayPdfBrandLogo`} onClick={backToProject}>
          <img
            className="pdfBrandLogo"
            src={projectName?.pdf_logo || projectName?.ecomedes_logo}
            alt="ecomedes logo"
          />
        </div>
        <RCButton
          onClick={backToProject}
          className={`${style.navbar} hidePdfElements`}
          onMouseEnter={() => setIsButonHoverd(true)}
          onMouseLeave={() => setIsButonHoverd(false)}>
          <div className={style.backToProjectIcon}>
            <BackIcon isButtonHovered={isButtonHovered} />
          </div>
          <span className={style.navTitle}>{t('projectEval.navbar.backToProject')}</span>
        </RCButton>
      </>
    </Padding>
  );
};

export default Navbar;
