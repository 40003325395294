type Product = {
  pre_rc: number | null;
  post_rc: number | null;
  recyclability: number | null;
  total_cost: number | null;
  quantity: number | null;
  price: number | null;
  name: string;
  carbon_breakdown?: {
    name: string;
    value: number;
  }[];
  carbon_units?: string;
  carbon_value?: string;
};

export type CircularityScoreType = {
  percentage: number;
  label: string;
};

export type CarbonBreakdownType = {
  totalUnits: number;
  unitsWithCarbonBreakdown: number;
  carbonBreakdown: Record<string, number>;
  unit: string;
  carbonValue: number;
}

export const calculateCircularityScores = (productList: Product[]): CircularityScoreType[] => {
  const { productsWithTotalCost, totalCost } = productList.reduce<{
    productsWithTotalCost: Product[];
    totalCost: number;
  }>(
    (acc, product) => {
      if (!product.total_cost) {
        return acc;
      }

      return {
        productsWithTotalCost: [...acc.productsWithTotalCost, product],
        totalCost: acc.totalCost + product.total_cost
      };
    },
    { productsWithTotalCost: [], totalCost: 0 }
  );

  return productsWithTotalCost.reduce<CircularityScoreType[]>(
    (acc, { pre_rc, post_rc, recyclability, quantity, price }) => {
      const scores = acc;

      if (quantity === null || price === null) {
        return scores;
      }

      const productPrice = quantity * price;

      if (pre_rc !== null) {
        scores[0].percentage += ((pre_rc * productPrice) / totalCost) * 100;
      }

      if (post_rc !== null) {
        scores[1].percentage += ((post_rc * productPrice) / totalCost) * 100;
      }

      if (recyclability !== null) {
        scores[2].percentage += ((recyclability * productPrice) / totalCost) * 100;
      }

      return scores;
    },
    [
      { label: 'Pre-Consumer Recycled Content', percentage: 0 },
      { label: 'Post-Consumer Recycled Content', percentage: 0 },
      { label: 'Recyclability', percentage: 0 }
    ]
  );
};

export const itemStyles = {
  'Raw Material Extraction and Processing': { color: '#b0dff4' },
  Manufacturing: { color: '#005c84' },
  Distribution: { color: '#0073a5' },
  'End of Life': { color: '#0099dc' },
}

export const calculateCarbonBreakdown = (
  productList: Product[]
): any => {
  const representedUnits = productList.reduce((acc: CarbonBreakdownType, product) => {
    const { totalUnits } = acc;
    let newTotalUnits = totalUnits;
    let unitsWithCarbonBreakdown = acc.unitsWithCarbonBreakdown;
    let carbonUnits = ''
    newTotalUnits += product.quantity || 0;
    let carbonValue = acc.carbonValue

    if (product.carbon_breakdown) {
      unitsWithCarbonBreakdown += product.quantity || 0;
      carbonUnits = product.carbon_units || '';
      carbonValue = carbonValue + (product.carbon_value ? parseFloat(product.carbon_value) : 0);
      product.carbon_breakdown.forEach(({ name, value }) => {
        if (acc.carbonBreakdown[name]) {
          acc.carbonBreakdown[name] += value;
        } else {
          acc.carbonBreakdown[name] = value;
        }
      });
    }

    return {
      totalUnits: newTotalUnits,
      unitsWithCarbonBreakdown,
      carbonBreakdown: acc.carbonBreakdown,
      unit: carbonUnits,
      carbonValue,
    };
  }
  , {
    totalUnits: 0,
    unitsWithCarbonBreakdown: 0,
    carbonBreakdown: {} as Record<string, number>,
    carbonValue: 0,
    unit: ''
  });

  return representedUnits;
}
