import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { PieChart } from 'echarts/charts';
import {
  GraphicComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';

import styles from './CircleChart.module.scss';
import progressBarsStyles from '../ProgressBars/ProgressBars.module.scss'

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  GraphicComponent
]);

type CircleChartProps = {
  data: {
    value: number;
    name: string;
    itemStyle: {
      color: string;
    };
  }[];
  carbonValue: number;
  units: string;
  title: string;
  icon: JSX.Element;
  badge?: string;
};

const graphicStyles = {
  fontSize: 24,
  fontFamily: 'Gotham600',
  textAlign: 'center'
};

export const CircleChart = ({ badge, data, carbonValue, units, icon, title }: CircleChartProps): JSX.Element => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstanceRef = useRef<echarts.ECharts | null>(null);
  console.log('units');
  console.log(units);
  console.log('data');
  console.log(data);
  console.log('carbonValue');
  console.log(carbonValue);

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = echarts.init(chartRef.current);

      chartInstanceRef.current = chartInstance;
      const option = {
        legend: {
          show: false
        },
        series: [
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: ['50%', '80%'],
            height: '240px',
            width: '100%',
            labelLine: {
              show: false
            },
            label: {
              show: false
            },
            data,
          }
        ],
        graphic: [
          {
            type: 'text',
            // Position text in the center
            left: 'center',
            top: '40%',
            style: {
              ...graphicStyles,
              fontSize: 28,
              text: carbonValue.toString()
            }
          },
          {
            type: 'text',
            left: 'center',
            top: '52%',
            style: {
              text: units,
              textAlign: 'center',
              fontSize: 16,
              fontFamily: 'Gotham500'
            }
          }
        ]
      };

      chartInstance.setOption(option);

      chartInstance.on('mouseover', (params) => {
        if (params.seriesType === 'pie' && params.dataIndex != null) {
          const sliceValue = params.value;

          chartInstance.setOption({
            graphic: [
              {
                type: 'text',
                top: '40%',
                style: {
                  text: sliceValue?.toString() ?? '',
                  ...graphicStyles
                }
              }
            ]
          });
        }
      });

      chartInstance.on('mouseout', (params) => {
        if (params.seriesType === 'pie') {
          chartInstance.setOption({
            graphic: [
              {
                type: 'text',
                top: '40%',
                style: {
                  text: carbonValue.toString(),
                  ...graphicStyles
                }
              }
            ]
          });
        }
      });
      const resizeChart = (): void => {
        chartInstance.resize();
      };

      window.addEventListener('resize', resizeChart);

      return () => {
        window.removeEventListener('resize', resizeChart);
        chartInstance.dispose();
      };
    }
  });

  const mouseEnter = (dataIndex: number): void => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex
      });

      chartInstanceRef.current.setOption({
        graphic: [
          {
            type: 'text',
            top: '40%',
            style: {
              text: data[dataIndex].value.toString(),
              ...graphicStyles
            }
          }
        ]
      });
    }
  };
  const mouseExit = (dataIndex: number): void => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex
      });

      chartInstanceRef.current.setOption({
        graphic: [
          {
            type: 'text',
            top: '40%',
            style: {
              text: carbonValue.toString(),
              ...graphicStyles
            }
          }
        ]
      });
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={progressBarsStyles.titleContainer}>
          <div className={classNames(
            styles.icon,
            progressBarsStyles.icon
          )}>{icon}</div>
          <h2 className={classNames(
            progressBarsStyles.title,
            styles.title,
          )}>{title}</h2>
        </div>
        {badge && <div className={progressBarsStyles.badge}>{badge}</div>}
      </div>
      <div className={styles.body}>
        <div ref={chartRef} style={{ width: '50%', height: '240px' }} />
        <div className={styles.legend}>
          {data.map((entry, index) => (
            <div
              className={styles.row}
              onMouseEnter={() => mouseEnter(index)}
              key={index}
              onMouseLeave={() => mouseExit(index)}>
              <div
                style={{
                  backgroundColor: entry.itemStyle.color,
                  height: entry.name.length > 22 ? 44 : 22
                }}
                className={styles.key}
              />
              <div className={styles.name}>{entry.name}</div>
              <div className={styles.percentage}>{(entry.value / carbonValue) * 100}%</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
