import classNames from 'classnames';

import { ProgressBar } from '../ProgressBar';

import progressBarsStyles from '../ProgressBars.module.scss';
import styles from './ProgressBarDouble.module.scss';

type ScoreType = {
  percentage: number;
  label: string;
};

type ProgressBarSingleProps = {
  icon: JSX.Element;
  title: string;
  badge?: string;
  scores: ScoreType[];
  footnote?: string;
};

function splitAlternating<T>(arr: T[]): T[][] {
  const numGroups = Math.ceil(arr.length / 2);
  const result: T[][] = Array.from({ length: numGroups }, () => []);

  arr.forEach((element, index) => {
    result[index % numGroups].push(element);
  });

  return result;
}

export const ProgressBarDouble = ({
  icon,
  title,
  badge,
  scores,
  footnote
}: ProgressBarSingleProps): JSX.Element => {
  const alternatingRows = splitAlternating(scores);

  return (
    <div className={progressBarsStyles.card}>
      <div className={progressBarsStyles.cardHeader}>
        <div className={progressBarsStyles.titleContainer}>
          <div className={progressBarsStyles.icon}>{icon}</div>
          <h2 className={progressBarsStyles.title}>{title}</h2>
        </div>
        {badge && <div className={progressBarsStyles.badge}>{badge}</div>}
      </div>
      <div className={styles.rows}>
        {alternatingRows.map((row, rowIndex) => (
          <div key={rowIndex} className={styles.columns}>
            {row.map((score, columnIndex) => {
              const percentage = score.percentage;

              return (
                <div
                  className={classNames({
                    [styles.firstColumn]: columnIndex === 0,
                    [styles.secondColumn]: columnIndex === 1
                  })}>
                  <ProgressBar
                    key={score.label}
                    label={score.label}
                    percentage={percentage}
                    isDouble={true}
                  />
                </div>
              );
            })}
          </div>
        ))}
      </div>
      {footnote && <div className={styles.footnote}>{footnote}</div>}
    </div>
  );
};
