import React from 'react';
import classNames from 'classnames';

import style from './customLinkButton.module.scss';
interface CustomLinkButtonProps {
  Icon?: any;
  text?: string;
  buttonClassname?: string;
  link?: string;
  onClick?: () => void;
  theme?: 'light' | 'dark';
}
const CustomLinkButton: React.FC<CustomLinkButtonProps> = ({
  Icon,
  text,
  // buttonClassname,
  link = '',
  theme = 'light',
  onClick
}) => (
  <a
    target="_blank"
    {...(link && { href: link })}
    className={classNames(
      {
        [style.light]: theme === 'light',
        [style.dark]: theme === 'dark'
      },
      style.button
      // buttonClassname
    )}
    onClick={onClick}
    rel="noreferrer">
    <p>{text}</p>
    {Icon}
  </a>
);

export default CustomLinkButton;
