import { ReactElement } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FaCheck } from 'react-icons/fa6';
import { GoDash } from 'react-icons/go';

import { baseURL } from '../../utils/baseUrl';
import { convertFractionToPercentage } from '../../utils/util';

import styles from './table.module.scss';
export interface ColumnProps<T> {
  type: string;
  key: string;
  title?: string | ReactElement;
  heading?: string | ReactElement;
  render?: (column: ColumnProps<T>, item: T) => ReactElement;
}

type Props<T> = {
  columns: Array<ColumnProps<T>>;
  data?: T[];
  className?: string;
  customHeaders?: any;
  tableStyling?: string;
  isScrollable?: boolean;
  footer?: any;
};

interface ProductInformationProps {
  thumbnail: string;
  productUrl?: string;
  brandName: string;
  name: string;
  brand?: string;
}

const getTableHeader = (customHeaders: any, column: any): string => {
  if (customHeaders && customHeaders[column?.title || column?.heading]) {
    return customHeaders[column?.title || column?.heading];
  } else {
    return column?.title || column?.heading;
  }
};

export const ProductInformation: React.FC<ProductInformationProps> = ({
  thumbnail,
  brandName,
  name,
  productUrl = ''
}): JSX.Element => (
  <div className={styles.product}>
    <div className={styles.image}>
      <img src={thumbnail} alt="test" />
    </div>
    <div className={`${styles.productDetails} ${styles.productTableTitle}`}>
      <h2 className={styles.productBrand}>{brandName}</h2>

      <a
        className={`${styles.productTitle} productLinkUnderLine`}
        href={`${baseURL({ productUrl })}`}>
        {name}
      </a>
    </div>
  </div>
);

type Value = any;

interface ValueProps {
  cellValue: Value;
}

const RenderValue: React.FC<ValueProps> = ({ cellValue }) => {
  const processValue = (value: string | any, key: string): Value => {
    if (key) {
      const impactSummaryKeys = ['currency', 'number', 'int', 'check-str', 'percent'];
      const IS_KEY_EXISTS = impactSummaryKeys.includes(key);

      if (IS_KEY_EXISTS && (value === 0 || value === '0')) {
        return '0';
      }
    }

    if (!value) {
      return <GoDash className={styles.tableCellDash} />;
    }

    if (key === 'check-str') {
      return value === undefined || value === null || value === '' ? (
        <GoDash className={styles.tableCellDash} />
      ) : (
        <div className={styles.checkStrCell}>
          <div className={styles.tableCellCheck}>
            <FaCheck style={{ fontSize: 32, color: '#49B108' }} />
          </div>
          <div className={styles.checkStrValue}>{value?.toUpperCase()}</div>
        </div>
      );
    }

    if (typeof value == 'boolean' || value === undefined || value === null) {
      return value ? (
        <div className={styles.tableCellCheck}>
          <FaCheck style={{ fontSize: 32, color: '#49B108' }} />
        </div>
      ) : (
        <GoDash className={styles.tableCellDash} />
      );
    } else {
      if (key === 'number') {
        return (value * 1).toLocaleString();
      }

      if (key === 'int') {
        if (typeof value === 'string') {
          return <GoDash className={styles.tableCellDash} />;
        }

        return (value * 1).toLocaleString();
      }

      if (key === 'percent') {
        return convertFractionToPercentage(value * 1);
      }

      if (key === 'currency') {
        return `$${value?.toLocaleString()}`;
      }

      return value;
    }
  };
  const value = processValue(cellValue.value, cellValue.key);

  return <div>{value}</div>;
};
const Table = <T extends { brand_name: string; name: string }>({
  data,
  columns,
  footer,
  className,
  isScrollable,
  customHeaders,
  tableStyling
}: Props<T>): JSX.Element => {
  const headers = columns.map((column, index) => (
    <th
      key={`headCell-${index}`}
      style={{
        textAlign: 'center'
      }}
      className={`${styles.th} ${index === 0 ? styles.th1 : ''}`}>
      {getTableHeader(customHeaders, column)}
    </th>
  ));
  const rows = !data?.length ? (
    <tr>
      <td colSpan={columns.length} className={`${styles.textCenter} ${styles.tableDataNotFound}`}>
        No data found
      </td>
    </tr>
  ) : (
    <>
      {data?.map((row, index) => (
        <tr key={`row-${index}`} className={`${styles.tr} ${className} ${styles.textCenter}`}>
          {columns.map((column, index2) => {
            const value = column.render
              ? column.render(column, row as T)
              : {
                  value: row[column.key as keyof typeof row] as string,
                  key: column.type
                };

            return (
              <td className={styles.td} key={`cell-${index2}`}>
                {index2 === 0 ? (
                  <span>
                    {isScrollable ? (
                      <AnchorLink
                        className={styles.productTableTitle}
                        href={`#${row.brand_name}_${row.name}`}>
                        <ProductInformation
                          brandName={row['brand_name' as keyof typeof row] as string}
                          name={row['name' as keyof typeof row] as string}
                          thumbnail={row['thumbnail' as keyof typeof row] as string}
                          productUrl={row['product_url' as keyof typeof row] as string}
                        />
                      </AnchorLink>
                    ) : (
                      <ProductInformation
                        brandName={row['brand_name' as keyof typeof row] as string}
                        name={row['name' as keyof typeof row] as string}
                        thumbnail={row['thumbnail' as keyof typeof row] as string}
                        productUrl={row['product_url' as keyof typeof row] as string}
                      />
                    )}
                  </span>
                ) : (
                  <RenderValue cellValue={value} />
                )}
              </td>
            );
          })}
        </tr>
      ))}
      {footer && (
        <tr className={`${styles.tr} ${className} ${styles.textCenter}`}>
          {columns.map((el, footerIndex) =>
            footerIndex === 0 ? (
              <td className={`${styles.td} ${styles.tdFooter} ${styles.tdFooterTitle}`}>Total</td>
            ) : (
              <td className={styles.tdFooter}>{footer[el.key]}</td>
            )
          )}
        </tr>
      )}
    </>
  );

  return (
    <div>
      <div className={`${styles.tableWrapper} ${tableStyling}`}>
        <div className={styles.container}>
          <table className={styles.table}>
            <thead className={styles.bgSlate400}>
              <tr>{headers}</tr>
            </thead>
            <tbody className={styles.tbody}>{rows}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
