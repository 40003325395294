import { ProgressBar } from '../ProgressBar';

import progressBarsStyles from '../ProgressBars.module.scss';
import styles from './ProgressBarSingle.module.scss';

type ScoreType = {
  percentage: number;
  label: string;
};

type ProgressBarSingleProps = {
  icon: JSX.Element;
  title: string;
  badge?: string;
  scores: ScoreType[];
  footnote?: string;
};

export const ProgressBarSingle = ({
  icon,
  title,
  badge,
  scores,
  footnote
}: ProgressBarSingleProps): JSX.Element => (
  <div className={progressBarsStyles.card}>
    <div className={progressBarsStyles.cardHeader}>
      <div className={progressBarsStyles.titleContainer}>
        <div className={progressBarsStyles.icon}>{icon}</div>
        <h2 className={progressBarsStyles.title}>{title}</h2>
      </div>
      {badge && <div className={progressBarsStyles.badge}>{badge}</div>}
    </div>
    {scores.map((score) => {
      const percentage = score.percentage;

      return <ProgressBar key={score.label} label={score.label} percentage={percentage} />;
    })}
    {footnote && <div className={styles.footnote}>{footnote}</div>}
  </div>
);
