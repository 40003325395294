import React, { useEffect } from 'react';
import { createRoot, Root } from 'react-dom/client';

import { AISearchTitleIcon } from '../assets/svgs/aiSearchTitleIcon';
import AISummaryContainer from '../components/AISearch/AISearchModal/aisumaryContainer';
import { AISearchContainer } from '../components/shared/Container/AISearchContainer';
import CustomButton from '../components/shared/CustomButton';
import { ErrorBoundaryWrapper } from '../components/shared/ErrorBoundary/ErrorBoundary';

import style from '../components/AISearch/aiSearch.module.scss';

let root: Root | null = null;

export function unmountAISearchBanner(): void {
  if (root) {
    root.unmount();
    root = null;
  }
}

export function mountAISearchBanner(element: HTMLElement): void {
  if (!root) {
    root = createRoot(element);
  }

  root.render(<AISearchBanner />);
}

const AISearchBanner: React.FC<any> = () => {
  useEffect(
    () => () => {
      unmountAISearchBanner();
    },
    []
  );

  return (
    <div className={style.sustainabilitySummaryPageContainer}>
      <div>
        <ErrorBoundaryWrapper>
          <AISearchContainer>
            <div className={style.aiSearchContainer}>
              <AISummaryContainer
                Icon={<AISearchTitleIcon strokeColor="black" />}
                title="IntelliBuild"
                hasBeta={true}
                description="IntelliBuild product finder lets you search in natural language – simply ask for what you need and advanced AI will find product options tailored to your project requirements. Sign up for early access."
                isAISemanticDescription={true}
              />
              <div
                className={style.aiSearchButtons}
                onClick={() => {
                  window.location.href = 'https://hub.ecomedes.com/aisearchsignup';
                }}>
                <CustomButton label="Sign Up" className={style.aiSearchButtonsStyle} />
              </div>
            </div>
          </AISearchContainer>
        </ErrorBoundaryWrapper>
      </div>
    </div>
  );
};

export default AISearchBanner;
