import React from 'react';

import SantizedHTMLRawData from '../../../../utils/sanitizeHTML';

import style from './aiSummaryConatiner.module.scss';

interface AISummaryContainerProps {
  Icon?: any;
  title?: string;
  description?: string;
  mainSummary?: boolean;
  productSummary?: boolean;
  isAISemanticDescription?: boolean;
  hasTitle?: boolean;
  hasBeta?: boolean;
}
const AISummaryContainer: React.FC<AISummaryContainerProps> = ({
  Icon,
  title,
  description,
  mainSummary,
  productSummary,
  isAISemanticDescription = false,
  hasTitle = true,
  hasBeta = true
}) => {
  const headerTitleClass = mainSummary
    ? style.modalmainSummaryHeaderTitle
    : productSummary
      ? style.productSummaryHeader
      : style.modalHeaderTitle;
  const headerDescriptionClass = mainSummary
    ? style.modalmainSummaryDescription
    : productSummary
      ? style.productSummaryDescription
      : style.modalHeaderDescription;

  return (
    <div className={style.modalHeaderSection}>
      <div className={style.modalHeaderContent}>
        {Icon}
        {hasTitle && <h3 className={headerTitleClass}>{title || ''}</h3>}
        {hasBeta && <div className={style.modalBetaTag}>BETA</div>}
      </div>
      <div
        className={`${isAISemanticDescription ? style.aiSearchContentDescription : headerDescriptionClass}`}>
        <SantizedHTMLRawData content={description || ''} />
      </div>
    </div>
  );
};

export default AISummaryContainer;
