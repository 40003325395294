import classNames from 'classnames';

import styles from './ProgressBar.module.scss';

type ProgressBarProps = {
  label: string;
  percentage: number;
  isDouble?: boolean;
};

export const ProgressBar = ({
  label,
  percentage,
  isDouble = false
}: ProgressBarProps): JSX.Element => (
  <div className={styles.progressBar}>
    <div className={styles.certification}>{label}</div>
    <div className={styles.cardBody}>
      <div
        className={classNames(styles.barContainer, {
          [styles.double]: isDouble,
          [styles.single]: !isDouble
        })}>
        <div className={styles.bar} style={{ width: `${percentage}%` }} />
      </div>
      <div className={styles.percentage}>{percentage.toFixed(0)}%</div>
    </div>
  </div>
);
