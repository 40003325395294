export const baseURL = ({
  productUrl,
  id,
  zipCode,
  pathname
}: {
  productUrl?: string;
  id?: string;
  zipCode?: string | number;
  pathname?: string;
}): string => {
  const { protocol, host } = window.location;

  if (id) {
    const url = new URL(`${pathname}/evaluation`, `${protocol}//${host}`);

    url.searchParams.set('rating-system', id);

    if (zipCode) {
      url.searchParams.set('location', zipCode.toString());
    }

    return url.toString();
  } else {
    return new URL(productUrl ?? '', `${protocol}//${host}`).toString();
  }
};
