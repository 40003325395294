import classNames from 'classnames';

import { baseURL } from '../../../utils/baseUrl';

import style from './accordionHeader.module.scss';

interface AccordionHeaderI {
  title?: string;
  icon?: JSX.Element;
  name?: string;
  image?: any;
  productUrl?: string | undefined;
  accordionHeaderTitle?: boolean;
  id?: string;
  hasBackground?: boolean;
  ratingSystem?: boolean;
}
const AccordionHeader = ({
  image,
  title,
  icon,
  name,
  productUrl,
  accordionHeaderTitle,
  id,
  hasBackground,
  ratingSystem = false
}: AccordionHeaderI): JSX.Element => (
  <div className={style.accrodionHeader} id={id}>
    <div
      className={classNames({
        [style.accrodionGrandTitle]: accordionHeaderTitle,
        [style.accordionHeaderTitle]: !accordionHeaderTitle,
        [style.accrodionHeaderImageLeft]: image
      })}>
      <div
        className={classNames({
          [style.accordionHeaderImg]: image,
          [style.accordionHeadericon]: !image
        })}>
        {icon && hasBackground ? <div className={style.accordianIcon}>{icon}</div> : icon}
        {image && (
          <img
            src={image}
            alt={`${name} product`}
            className={classNames({
              [style.ratingsImage]: ratingSystem,
              [style.accordionHeaderImage]: !ratingSystem
            })}
          />
        )}
      </div>
      <div className={style.accordianHeaderContent}>
        <div
          className={classNames({
            [style.accrodionHeaderTitle]: name || accordionHeaderTitle,
            [style.accrodionHeaderDefaultTitle]: !name && !accordionHeaderTitle
          })}>
          {title}
        </div>
        <a
          className={classNames(style.accrodionHeaderName, 'pdfAccordionHeaderName')}
          href={productUrl ? `${baseURL({ productUrl })}` : ''}>
          {name}
        </a>
      </div>
    </div>
  </div>
);

export default AccordionHeader;
